body {
	overflow-x: hidden;
  position: relative;
  height: 100%;
}

.mainHome {
  padding: 1rem;
  font-family: Georgia, serif;
}

@media screen and (max-width: 600px) {

  h2 {
    font-size: 18px;
  }
  
  .content {
    font-size: 12px;
    height: 10%;
  }
}  


.GestureGallery {
  border-width: 1px;
  border-radius: .25rem;
  box-shadow: 0 .5rem 1rem black;
}

h2 {
  font-weight: 600;
}

.aboutUs, .timberWindows {
  padding-top: 5%;
}


.gallery {
  padding-top: 5%;
  margin: 0 auto;
  width: 800px;
  height: 553px;
}

@media screen and (max-width: 600px) {
  .gallery {
    width: 350px;
    height: 225px;
    padding-top: 2%;
  }
}

.windowsContainer {
  position: relative;
  padding-top: 7%;
}

.windowsImage {
  border-radius: 20px;
}

.workersContainer {
  position: relative;
  padding-top: 5%;
}

.workerImage {
  width: 80%;
}

.galleryElement {
  padding-top: 20px;
}

img.cardImage {
  width: 213px;
  height: 195px;
}

.slick-list {
  justify-content: center;
}

.viewFilterContainer {
  display: flex;
}

@media screen and (max-width: 600px) {
  .viewFilterContainer {
    display: block !important;
  }
}

.viewFilter {
  cursor: pointer;
  margin-right: 5%;
  border-radius: 2%;
  padding: 0.5% 1%;
}

.viewFilter:hover {
  background: #ffd19a
}

.slick-arrow, slick-next, .slick-next, .slick-prev, .slick-prev {
  background-color: grey !important;
  height: 20px;
  width: 20px;
  border-radius: 100px;
  z-index: 1000;
}

.slick-arrow:hover {
  background-color: brown !important;
  z-index: 1000;
}

@media screen and (max-width: 600px) {
  .workersColumn, .windowsColumn {
    padding-bottom: 10%;
  }
  .workerImage, .windowsImage {
      width: 50% !important;
  }
}

img {
  width: 100%;
}



.homeGallery {
  object-fit: none !important;
}

.galleryImage {
  vertical-align: unset !important;
  border-radius: 30px;
  box-shadow: 0 .2rem 0.2rem black;
  margin-bottom: 10px;
  cursor: pointer;
}

footer {
  margin-top: 4%;
  font-family: Georgia, serif;
  background: linear-gradient(#606060, black);
  box-shadow: 0 .5rem 1rem black;
  color: white;
}


@media screen and (max-width: 600px) {
  .addressFooter, .emailPhone {
    text-align: center;
    font-size: 12px;
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
	}
  h5 {
    font-size: 16px;
    font-weight: 600;
  }
}

@media screen and (max-width: 600px) {
  .copyright, .copyrightContent, .footerLogo {
    text-align: center;
    font-size: 12px;
	}
}

@media screen and (max-width: 600px) {
  .navbar-brand {
    margin-right: 0 !important;
  }
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.loading {
  text-align: center;
}

.MuiCircularProgress-svg {
  color: orange;
}

@media screen and (min-width: 800px) {
  .container {
    min-height: 100vh;
  }
}

.instagramGallery {
  padding-top: 5%;
}
